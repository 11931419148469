import React, { useState, useEffect, Fragment } from 'react'
import Layout from '../../components/Layout'
import axios from 'axios'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../../components/partials/HeaderSEO'

import trial_illustration from '../../resources/images/trial/illustration.jpeg'
import undraw_version_control_9bpv from '../../resources/images/vectors/undraw_version_control_9bpv.svg'

import Logos from '../../components/partials/Logos9'
import Form from '../../components/forms/Form'
import { getEmailLocalStorage } from '../../helpers/getEmailStorage'

import loader from '../../resources/images/spinner.gif'

import { getCookieByName } from '../../helpers/getCookieByName'

declare const window: any

interface ResponseData {
  identifier: string
  downloadURL: string
  end: string
}

const lensesEnterprise = () => {
  const data = useStaticQuery(graphql`
    query LensesTrialSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Form_Trial" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const [loadingData, setLoadingData] = useState({
    loading: false,
    lambdaURL: '',
  })
  const { loading, lambdaURL } = loadingData

  const [activeChoiceData, setActiveChoiceData] = useState({
    title: 'Start a Trial',
    d_paragraph:
      'Run Lenses as a JVM application running in a virtual machine or physical hardware, as a linux archive, container or cloud',
    bullet1: 'Limited-Time Free Trial',
    bullet2: 'Connect to your existing infrastructure',
    bullet3: 'Scale out flows on Kubernetes',
    bullet4: 'Integrate monitoring & alerting',
    d_image: trial_illustration,
    alt_image: 'trial enterprise',
    trial_source: 'NONE',
    trial_action: 'https://go.pardot.com/l/877242/2022-01-17/9d8pyr',
    submitValue: 'Download',
  })

  const {
    title,
    d_paragraph,
    bullet1,
    bullet2,
    bullet3,
    bullet4,
    d_image,
    alt_image,
    trial_source,
    trial_action,
    submitValue,
  } = activeChoiceData

  // Read URL
  useEffect(() => {
    try {
      let url_string = window.location.href
      var url = new URL(url_string)
      let source = url.searchParams.get('s')
      if (source) {
        //Get string value
        if (source.startsWith('usecase')) {
          setActiveChoiceData({
            ...activeChoiceData,
            d_paragraph:
              'Download our all-in-one instance of Lenses & Kafka. Perfect for exploring all the features of Lenses',
            bullet1: 'Single docker container',
            bullet2: 'Installed in minutes',
            bullet3: 'Includes a working Kafka environment',
            bullet4: 'Pre configured with sample data flows',
            d_image: undraw_version_control_9bpv,
            alt_image: 'Box use cases',
            trial_source: source,
          })
        } else if (source.startsWith('connector')) {
          setActiveChoiceData({
            ...activeChoiceData,
            d_paragraph:
              'Perfect for testing a connector and exploring the features of Lenses',
            bullet1: 'Single docker container, Installed in minutes',
            bullet2: 'Includes working Kafka and Connect cluster',
            bullet3: 'Pre configured with sample data flows',
            bullet4: 'Packaged with over 30 connectors',
            d_image: undraw_version_control_9bpv,
            alt_image: 'Box connector',
          })
        } else if (source === 'azure') {
          setActiveChoiceData({
            ...activeChoiceData,
            title: 'Start a trial \n on Azure',
            trial_source: 'AZURE_HDINSIGHT',
          })
        } else if (source === 'aiven') {
          setActiveChoiceData({
            ...activeChoiceData,
            trial_source: 'AIVEN',
            title: 'Trial Lenses for my Aiven Kafka',
            d_paragraph:
              'Run Lenses over your Aiven Kafka cluster for 14 days free',
            bullet1: '100% Kafka self-service for everyone',
            bullet2: 'Apply governance and data compliance',
            bullet3: 'Setup in minutes',
            bullet4: null,
            submitValue: 'Start Free Trial',
          })
        } else if (source === 'confluent') {
          setActiveChoiceData({
            ...activeChoiceData,
            trial_source: 'CONFLUENT',
          })
        } else if (source === 'linux') {
          // DIFFERENT HANDLER FOR LINUX DOCKER AND K8TS
          setActiveChoiceData({
            ...activeChoiceData,
            trial_source: 'LINUX',
            trial_action: 'https://go.pardot.com/l/877242/2020-08-04/4fkwlk',
          })
        } else if (source === 'docker') {
          setActiveChoiceData({
            ...activeChoiceData,
            trial_source: 'DOCKER',
            trial_action: 'https://go.pardot.com/l/877242/2020-08-04/4fkwlk',
          })
        } else if (source === 'kubernetes') {
          setActiveChoiceData({
            ...activeChoiceData,
            trial_source: 'KUBERNETES',
            trial_action: 'https://go.pardot.com/l/877242/2020-08-04/4fkwlk',
          })
        } else if (source === 'other') {
          setActiveChoiceData({
            ...activeChoiceData,
            trial_source: 'OTHER',
          })
        } else {
          setActiveChoiceData({
            ...activeChoiceData,
            trial_source: 'OTHER',
          })
        }
      } else {
        setActiveChoiceData({
          ...activeChoiceData,
          trial_source: 'OTHER',
        })
      }
      let emailStorage = getEmailLocalStorage()
      if (emailStorage) {
        document.querySelector<HTMLInputElement>('#email').value = emailStorage
      }
    } catch (err) {
      console.log(err)
    }

    if (location.href.includes('https://www.dev.lenses.io/')) {
      setLoadingData({
        ...loadingData,
        lambdaURL:
          'https://384in5y2mk.execute-api.eu-west-1.amazonaws.com/dev/api/v1/leads',
      })
    } else {
      setLoadingData({
        ...loadingData,
        lambdaURL:
          'https://s1qhfrb27e.execute-api.eu-west-1.amazonaws.com/prod/api/v1/leads',
      })
    }

    // es-lint-disable-next-line
  }, [])

  const handleFormSubmit = (e) => {
    e.preventDefault()
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#timestamp').value =
      new Date().toLocaleString()
    if (
      document.querySelector<HTMLInputElement>('#terms_of_use').checked &&
      document.querySelector<HTMLInputElement>('#email').value !== '' &&
      document.querySelector<HTMLInputElement>('#first_name').value !== '' &&
      document.querySelector<HTMLInputElement>('#last_name').value !== ''
    ) {
      document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
      setLoadingData({
        ...loadingData,
        loading: true,
      })
      let emailValue = document.querySelector<HTMLInputElement>('#email').value
      let companyValue = 'Not provided'
      axios
        .post(`${lambdaURL}`, {
          email: emailValue,
          source: 'website',
          company: companyValue,
          license: 'trial',
        })
        .then((res) => {
          const data = res.data as ResponseData
          document.querySelector<HTMLInputElement>('#license').value =
            data.identifier
          document.querySelector<HTMLInputElement>('#expiration_date').value =
            data.end
          document.querySelector<HTMLInputElement>('#downloadURL').value =
            data.downloadURL
          document
            .querySelector<HTMLFormElement>('#zoominfo-default-form')
            .submit()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <Layout onlyLogo={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link
          rel="canonical"
          href="https://lenses.io/downloads/lenses-enterprise/"
        />
      </Helmet>
      {loading && (
        <section className="pt-5 " style={{ marginTop: '250px' }}>
          <div className="text-center primary-text fw-900 f-20">
            Please wait...
          </div>
          <div className="text-center pb-5 justify-content mb-5 mt-4">
            <img
              className=" pb-5"
              src={loader}
              alt="spinner"
              style={{ marginBottom: '1000px' }}
            />
          </div>
        </section>
      )}

      <Fragment>
        <section>
          <div className="container-1 hero-margin">
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-lg-5 col-10 col-md-9 pt-5">
                <h1 id="heading-title">{title}</h1>
                <p className="hero-description-dark pb-0" id="d-paragraph">
                  {d_paragraph}
                </p>
                <p className="hero-description-dark pt-0">
                  <i
                    className="fa fa-check contact-us-arrow pr-1"
                    aria-hidden="true"
                  ></i>
                  {bullet1} <br />
                  <i
                    className="fa fa-check contact-us-arrow pr-1"
                    aria-hidden="true"
                  ></i>
                  {bullet2}
                  <br />
                  <i
                    className="fa fa-check contact-us-arrow pr-1"
                    aria-hidden="true"
                  ></i>
                  {bullet3}
                  <br />
                  {bullet4 ? (
                    <Fragment>
                      <i
                        className="fa fa-check contact-us-arrow pr-1"
                        aria-hidden="true"
                      ></i>
                      {bullet4}
                      <br />
                    </Fragment>
                  ) : (
                    ''
                  )}
                </p>
                {d_image && (
                  <img
                    id="d-img"
                    className="img-fluid mx-auto d-block"
                    src={d_image}
                    alt={alt_image}
                  />
                )}
              </div>
              <div className="px-0 col-lg-4 offset-lg-2 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom">
                <div className="footer-color py-5 px-2 rounded">
                  <div className="d-flex flex-wrap">
                    <div className="col-md-12 mobile-padding-reset">
                      {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                      <Form
                        formId="zoominfo-default-form"
                        action={trial_action}
                        onSubmit={(e) => handleFormSubmit(e)}
                        inputId="formsubmit"
                        inputValue={submitValue}
                        firstName={'required'}
                        lastName={'required'}
                        email={'required'}
                        type={'trial'}
                        trial_source={trial_source}
                        validateEmailFlag
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-2 mb-5 pt-3">
          <div className="container-1">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="paragraph-title pt-5">
                  Trusted by over 25,000 developers
                </h2>
              </div>
              <Logos />
            </div>
          </div>
        </section>

        <section className="pb-5 mb-5">
          <div className="container-1">
            <div className="row mt-4">
              <div className="col-md-8 offset-md-2">
                <div className="row">
                  <div className="col-md-6 p-5">
                    <h2 className="card-title paragraph-title p-0">
                      Get a Live Demo
                    </h2>
                    <p className="card-text">
                      Schedule a one-on-one live technical demo of Lenses.
                    </p>
                    <a href="/contact/lenses-demo/" className=" link-text">
                      Request Now →
                    </a>
                  </div>
                  <div className="col-md-6 p-5">
                    <h2 className="card-title paragraph-title p-0">
                      Get a Quote
                    </h2>
                    <p className="card-text">
                      Want to connect to your cluster or try on cloud?
                    </p>
                    <a href="/contact-us/" className=" link-text">
                      Contact us →
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    </Layout>
  )
}

export default lensesEnterprise
