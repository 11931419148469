import React, { Fragment } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const logos9 = () => {
    return (
        <Fragment>
            <div className='col-md-4 col-12'>
                <div className='row mt-4'>
                    <div className='col-md-4 col-4 opct-7 customer-gray'>
                        <StaticImage
                            className='w-100 mt-2'
                            src='../../resources/images/clients/grid/gray/zopa.png'
                            placeholder='tracedSVG'
                            alt='Zopa'
                        />
                    </div>
                    <div className='col-md-4 col-4 opct-7 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            placeholder='tracedSVG'
                            src='../../resources/images/clients/grid/gray/generali.png'
                            alt='Generali'
                        />
                    </div>
                    <div className='col-md-4 col-4 opct-7 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            src='../../resources/images/clients/grid/gray/accenture.png'
                            placeholder='tracedSVG'
                            alt='Accenture'
                        />
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-12'>
                <div className='row mt-4'>
                    <div className='col-md-4 col-4 opct-7 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            src='../../resources/images/clients/grid/gray/daimler.png'
                            placeholder='tracedSVG'
                            alt='Daimler'
                        />
                    </div>
                    <div className='col-md-4 col-4 opct-7 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            src='../../resources/images/clients/grid/gray/adidas.png'
                            placeholder='tracedSVG'
                            alt='Adidas'
                        />
                    </div>
                    <div className='col-md-4 col-4 opct-7 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            src='../../resources/images/clients/grid/gray/sas.png'
                            placeholder='tracedSVG'
                            alt='SAS'
                        />
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-12'>
                <div className='row mt-4'>
                    <div className='col-md-4 col-4 opct-7 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            src='../../resources/images/clients/grid/gray/babylon.png'
                            placeholder='tracedSVG'
                            alt='Babylon'
                        />
                    </div>
                    <div className='col-md-4 col-4 opct-7 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            src='../../resources/images/clients/grid/gray/playtika.png'
                            placeholder='tracedSVG'
                            alt='Playtika'
                        />
                    </div>
                    <div className='col-md-4 col-4 opct-4 pt-2 customer-gray'>
                        <StaticImage
                            className='w-100'
                            src='../../resources/images/clients/grid/gray/komatsu.png'
                            placeholder='tracedSVG'
                            alt='Komatsu'
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default logos9
